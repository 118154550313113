import { usePopup } from '@/compositions/popup';
import { genMenuItemDownload } from '@/utils/list-generators';
import downloadBlob from '@/utils/downloadBlob';
import useMedia from '@/compositions/media';
import { endOfDay, toDate, parseISO, parse, startOfDay } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

export function useObjectsItemMenu() {
  const popup = usePopup();
  const { getObjectHistory } = useMedia();

  return {
    genMenu: ({ schemaId }) => {
      return [
        genMenuItemDownload(
          () => {
            popup.open({
              component: () =>
                import('@/components/history-list-card/HistoryDateRangePopup'),
              props: {
                onSubmit: async range => {
                  const data = {
                    schema_id: schemaId,
                    from: zonedTimeToUtc(
                      startOfDay(parseISO(range.from)),
                      'Etc/UTC'
                    ).toISOString(),
                    to: zonedTimeToUtc(
                      endOfDay(parseISO(range.to)),
                      'Etc/UTC'
                    ).toISOString()
                  };
                  getObjectHistory(data)
                    .then(res => {
                      downloadBlob(
                        res.data,
                        `PixelMonitor_ObjectsHistory_${range.from}-${range.to}.csv`,
                        res.data.type
                      );
                    })
                    .catch(() => {})
                    .finally(() => {});
                }
              }
            });
          },
          { title: 'Download history' }
        )
      ];
    }
  };
}
